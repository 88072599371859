import { ScrollTo } from '../../../pages/_v2/commons/ts/ScrollTo';

export class OfferSubscriptionForm {
    
    constructor() {
        try {
            this.addFormListeners();
        } catch {}
    }

        // addFormListeners add listeners to the All in newsletter component
        addFormListeners(): void {
            const submitBtn: HTMLButtonElement = <HTMLButtonElement>document.querySelector('.js-submit-button');
            const emailField: HTMLInputElement = document.querySelector('#email-newsletter');
            const nameField: HTMLInputElement = document.querySelector('#name-newsletter');
            if (!nameField || !emailField || !submitBtn)
                return;
    
            new ScrollTo(document.documentElement, document.querySelector('.js-button-search'), 0);
    
            let isNameValid = false;
            let isEmailValid = false;
            let showErrorMsg = false;
    
            function updateSubmitBtn(): void {
                const isValid = isNameValid && isEmailValid;
                submitBtn.disabled = !isValid;
            }
    
            function setNameValid(isValid: boolean): void {
                isNameValid = isValid;
                updateSubmitBtn();
            }
            
            function setEmailValid(isValid: boolean): void {
                isEmailValid = isValid;
                updateSubmitBtn();
    
                if (isValid) {
                    showErrorMsg = true;
                    emailField.parentElement.classList.remove('mdc-text-field--invalid');
                }
                else if (showErrorMsg) {
                    emailField.parentElement.classList.add('mdc-text-field--invalid');
                }
            }
    
            function validateNameField(): void {
                setNameValid(nameField.value.length > 0);
            }
    
            function validateEmailField(): void {
                const isValid: boolean = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                    emailField.value
                );
    
                setEmailValid(isValid);
            };
    
            nameField.addEventListener('keyup', validateNameField);
            emailField.addEventListener('keyup', validateEmailField);
    
            validateNameField();
            validateEmailField();
        }
}