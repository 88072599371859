const AmplitudeService = require('../../../../src/_v2/application/components/amplitude/amplitude-config');

export class AmplitudeHandler {
    // handleAmplitudeEvents Listen to Ui Events and emmit them to amplitude
    static handleAmplitudeEvents(pageName: String): void {
        const busCompanyName = document.body.dataset.busCompanyName;
        AmplitudeService.sendAmplitudeEvent('Pageview bus company page', {
            description: 'Page view specific bus company page',
            busCompanyName
        });

        [].map.call(document.querySelectorAll('.mdc-tab'), (el: Element) => {
            el.addEventListener('click', () => {
                AmplitudeService.sendAmplitudeEvent('Tab click', {
                    description:
                        'User clicked in a tab on the route list component',
                    page: pageName
                });
            });
        });

        [].map.call(
            document.querySelectorAll('.state-list-item'),
            (el: Element) => {
                el.addEventListener('click', () => {
                    AmplitudeService.sendAmplitudeEvent('Route click', {
                        description:
                            'User clicked on a route on the mdc list component',
                        page: pageName
                    });
                });
            }
        );

        document.querySelector('.js-route-search-amplitude') &&
            document
                .querySelector('.js-route-search-amplitude')
                .addEventListener('blur', () => {
                    AmplitudeService.sendAmplitudeEvent(
                        'search route in tabs - field select',
                        {
                            description:
                                'User click on the input at the search form for the tab list component'
                        }
                    );
                });

        document.querySelector('.js-origin-amplitude') &&
            document
                .querySelector('.js-origin-amplitude')
                .addEventListener('blur', () => {
                    AmplitudeService.sendAmplitudeEvent('origin selection', {
                        description: 'Click on form search origin input',
                        page: pageName
                    });
                });

        document.querySelector('.js-destination-amplitude') &&
            document
                .querySelector('.js-destination-amplitude')
                .addEventListener('blur', () => {
                    AmplitudeService.sendAmplitudeEvent(
                        'destination selection',
                        {
                            description:
                                'Click on form search destination input',
                            page: pageName
                        }
                    );
                });

        document.querySelector('.js-dateGo-amplitude') &&
            document
                .querySelector('.js-dateGo-amplitude')
                .addEventListener('blur', () => {
                    AmplitudeService.sendAmplitudeEvent(
                        'departure date selection',
                        {
                            description:
                                'Click on form search departure date input',
                            page: pageName
                        }
                    );
                });

        document.querySelector('.js-search-button-click-amplitude') &&
            document
                .querySelector('.js-search-button-click-amplitude')
                .addEventListener('click', () => {
                    AmplitudeService.sendAmplitudeEvent('Search - click', {
                        description: 'Click on form search submit input',
                        page: pageName
                    });
                });

        document.querySelector('.js-search-submit-amplitude') &&
            document
                .querySelector('.js-search-submit-amplitude')
                .addEventListener('click', () => {
                    AmplitudeService.sendAmplitudeEvent(
                        'Search route in tabs - button click',
                        {
                            description:
                                'User clicked on the submit button at the search form for the tab list component'
                        }
                    );
                });

        if (document.querySelector('.js-dateBack-amplitude')) {
            document
                .querySelector('.js-dateBack-amplitude')
                .addEventListener('click', () => {
                    AmplitudeService.sendAmplitudeEvent(
                        'arrival date selection',
                        {
                            description:
                                'Click on form search arrival date input',
                            page: pageName
                        }
                    );
                });
        }
    }
}
