/**
 * @description Scripts Utilitários
 */
var dayjs = require('dayjs');
var email = document.querySelector('.js-amplitude-input-email') || document.querySelector("[name='payment.buyerInfo.email']");

module.exports = {

    data: {
        userDevice: function() {
            var width = window.innerWidth;
            //fazendo um cache do valor do width para evitar o reflow
            window.gvWidth = width;
            return width < 767
                ? 'phone'
                : width >= 768 && width < 1000
                    ? 'tablet'
                    : 'desktop';
        },
        userEmail: document.querySelector("body").getAttribute("data-user-email") || (email && email.value) || localStorage.getItem("user-email")
    },

    click_touch: "ontouchstart" in document.documentElement ? "touchstart" : "click",

    log: function (type, msg) {
        if (!window.LE) return;
        window.LE[type || "info"](JSON.stringify(msg) + ", page=" + window.location.href + ", useragent=" + navigator.userAgent + ", device=" + this.data.userDevice);
    },

    removeAccents: function (str) {
        var removeAccents_map = {
            'Ã': 'A',
            'Â': 'A',
            'Á': 'A',
            'ã': 'a',
            'â': 'a',
            'á': 'a',
            'à': 'a',
            'É': 'E',
            'Ê': 'E',
            'È': 'E',
            'é': 'e',
            'ê': 'e',
            'è': 'e',
            'Í': 'I',
            'Î': 'I',
            'Ì': 'I',
            'î': 'I',
            'í': 'i',
            'ì': 'i',
            'Ô': 'O',
            'Õ': 'O',
            'Ó': 'O',
            'Ò': 'O',
            'ô': 'o',
            'õ': 'o',
            'ó': 'o',
            'ò': 'o',
            'Ú': 'U',
            'Ù': 'U',
            'ú': 'u',
            'ù': 'u',
            'ç': 'c'
        };
        return str.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
            return removeAccents_map[a] || a;
        });
    },

    getVarUrl: function () {
        var queryParams = window.location.search;

        if(!queryParams || queryParams.indexOf('?') < 0) return [];

        return queryParams.split('?')[1].split('&');
    },

    getUrl: function (queryParamName, queryParamValue) {
        if (!queryParamName || queryParamValue === undefined) throw 'Informe o QueryParam';

        var url = window.location.href;
        return url + (/\?/.test(url) ? '&' : '?') + queryParamName + '=' + queryParamValue;
    },

    addUrlStatus: function (queryParamName, queryParamObj) {

        if (this.data.userDevice() == 'phone') {
            var url = this.getVarUrl();

            if (url.indexOf(queryParamName + '=' + true) < 0) history.pushState(queryParamObj, '', this.getUrl(queryParamName, true));
        }
    },

    //transform from DD/MM/YYYY to dayjs
    parseDate: function(str) {
        if (str && str.length == 10) {
            var sarr = str.split('/');
            if (sarr.length == 3) {
                return dayjs('' + sarr[2] + '-' + sarr[1] + '-' + sarr[0]);
            }
        }
        return null;
    }
};
