import * as amplitude from '../../../../../../src/_v2/application/components/amplitude/amplitude-config';

export class AmplitudeUtils {
    userData: Object;

    constructor(pageName?: string) {
        if(!window['gvWidth']){
            window['gvWidth'] = window.innerWidth;
        }
        
        this.userData = {
            userScreenSize: window['gvWidth'],
        };

        if (!!pageName)
            this.userData['pageName'] = pageName;
    }
    
    sendEvents(): void {
        amplitude.sendEvents();
    }

    log(elmSelector: string, event: string, callBack: () => void): void {
        const elm: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll(elmSelector);
        const elmLength: number = elm.length;

        if (elmLength > 0) {
            for (let i: number = 0; i < elmLength; i++) {
                elm[i].addEventListener(event, callBack);
            }
        }
    }

    sendEvent(eventName: string, eventProperties: Object = {}): void {
        amplitude.sendAmplitudeEvent(eventName, eventProperties);
    }

    logOnLoad(eventName: string, eventProperties: Object): void {
        Object.assign(eventProperties, this.userData);
        amplitude.sendAmplitudeEvent(eventName, eventProperties);
    }

    logOnScroll(eventName: string, eventProperties: Object): void {
        let status: Boolean = false;
        window.addEventListener('scroll', () => {
            if (!status) {
                Object.assign(eventProperties, this.userData);
                amplitude.sendAmplitudeEvent(eventName, eventProperties);
                status = true;
            }
        });
    }

    logOnSeeElement(elmSelector: string, eventName: string, eventProperties: Object): void {
        let status: boolean = false;
        const element: Element = document.querySelector(elmSelector);
        if (element) {
            const elmPosition: number = element['offsetTop'];
            window.addEventListener('scroll', () => {
                const pageP: number = window.pageYOffset;
                if (!status) {
                    if (pageP >= elmPosition) {
                        Object.assign(eventProperties, this.userData);
                        amplitude.sendAmplitudeEvent(eventName, eventProperties);
                        status = true;
                    }
                }
            });
        }
    }

    logOnClick(elmSelector: string, eventName: string, eventProperties: Object = {}): void {
        this.log(elmSelector, 'click', () => {
            Object.assign(eventProperties, this.userData);
            amplitude.sendAmplitudeEvent(eventName, eventProperties);
        });
    }

    logOnFocus(elmSelector: string, eventName: string, eventProperties: Object): void {
        this.log(elmSelector, 'focus', () => {
            Object.assign(eventProperties, this.userData);
            amplitude.sendAmplitudeEvent(eventName, eventProperties);
        });
    }

    logOnSubmit(elmSelector: string, eventName: string, eventProperties: Object): void {
        this.log(elmSelector, 'submit', () => {
            Object.assign(eventProperties, this.userData);
            amplitude.sendAmplitudeEvent(eventName, eventProperties);
        });
    }

    logOnDrag(elmSelector: string, eventName: string, eventProperties: Object): void {
        const elm: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll(elmSelector);
        const elmLength: number = elm.length;
        let status: Boolean = false;

        for (let i: number = 0; i < elmLength; i++) {
            elm[i].addEventListener('mousedown', () => (status = true));

            elm[i].addEventListener('mouseup', () => (status = false));
        }

        this.log(elmSelector, 'mousemove', () => {
            if (status) {
                amplitude.sendAmplitudeEvent(eventName, eventProperties);
                status = false;
            }
        });
    }

    static sendAmplitudeEvent(eventName: string, eventProperties: Object = {}): void {
        amplitude.sendAmplitudeEvent(eventName, eventProperties);
    }
}
