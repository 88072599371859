export class ScrollTo {
    element: Element;
    triggerScroll: Element;
    to: number;
    duration: number;

    /**
     * @description Scrolls element to desired position
     * @param element Element that will be scrolled
     * @param triggerScroll What will trigger the scroll when clicked
     * @param to Can be either the element that you want to put into view or the number of the offset you want to scroll
     * @param duration Animation duration, defaults to 500
     */
    constructor(
        element: Element,
        triggerScroll: Element,
        to: number | Element,
        duration: number = 500
    ) {
        this.element = element;
        this.duration = duration;
        this.triggerScroll = triggerScroll;

        if (typeof to === 'number') {
            this.to = to;
        } else {
            this.to = to.getBoundingClientRect().top;
        }

        this.triggerScroll.addEventListener('click', () => {
            this.scrollTo(this.element, this.to, this.duration);
        });
    }

    easeInOutQuad(t: number, b: number, c: number, d: number): number {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    scrollTo(element: Element, to: number = 0, duration: number): void {
        const start: number = element.scrollTop;
        const change: number = to - start;
        const increment: number = 20;
        let currentTime: number = 0;
        let self: ScrollTo = this;

        const animateScroll: Function = (): void => {
            currentTime += increment;

            const val: number = self.easeInOutQuad(
                currentTime,
                start,
                change,
                duration
            );

            element.scrollTop = val;

            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };

        animateScroll();
    }
}
