import { AmplitudeHandler } from './bus-company.amplitude';
import { GVCookiesLgpdAlert } from '../commons/components/gv-cookie-lgpd-alert';
import { OfferSubscriptionForm } from '../../../components/_v2/components/offer-subscription-form';

export class BusCompanyCriticController {
    constructor() {
        new GVCookiesLgpdAlert();
        new OfferSubscriptionForm();

        AmplitudeHandler.handleAmplitudeEvents('bus company page');
    }
}

new BusCompanyCriticController();